<div class="container container-mb">

  <mat-stepper
    [linear]="true"
    [orientation]="(stepperOrientation | async)!"
    #stepper
    [selectedIndex]="currentStep">

    <mat-step [stepControl]="step1">
      <ng-template matStepLabel>Personalien</ng-template>
      <app-personal-information></app-personal-information>
    </mat-step>

    <mat-step [stepControl]="step2">
      <ng-template matStepLabel>Kundenberater</ng-template>
      <app-account-manager></app-account-manager>
    </mat-step>

    <mat-step [stepControl]="step3" optional>
      <ng-template matStepLabel>Versicherungsverträge</ng-template>
      <app-insurance-contracts></app-insurance-contracts>
    </mat-step>

    <mat-step [stepControl]="step4">
      <ng-template matStepLabel>Unterschreiben</ng-template>
      <app-signature></app-signature>
    </mat-step>

  </mat-stepper>
</div>


<div class="stepper-navigation">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <div class="container">
    <div class="row align-items-center">
      <div class="col">
        <button mat-icon-button
                class="stepper-previous"
                (click)="goBack(stepper)"
                style="text-indent: 2px">
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
      </div>
      <div class="col text-right" *ngIf="stepper.selectedIndex != 3">
        <button mat-icon-button
                class="stepper-next"
                (click)="goForward(stepper)">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </div>
      <div class="col text-right" *ngIf="stepper.selectedIndex == 3 && !isSigned && isAuthorized">
        <button mat-button class="white-button" (click)="createMandate()">
          speichern und an Kunde senden
        </button>
      </div>
      <div class="col text-right" *ngIf="stepper.selectedIndex == 3 && isSigned">
        <button mat-button class="white-button" (click)="submitMandate()">
          Maklermandat einreichen
        </button>
      </div>
    </div>
  </div>
</div>
