import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrokerMandateFormComponent } from "./broker-mandate-form/broker-mandate-form.component";
import { LoginComponent } from "./account/login/login.component";
import { SlugValidationService } from "./shared/services/slug-validation.service";
import { AccountComponent } from "./account/account/account.component";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { HomepageSubbrokerComponent } from "./pages/homepage-subbroker/homepage-subbroker.component";
import { ForgottPasswordComponent } from "./account/forgott-password/forgott-password.component";
import { SuccessComponent } from "./pages/success/success.component";
import { ImpressumComponent } from "./pages/impressum/impressum.component";
import { DisclaimerComponent } from "./pages/disclaimer/disclaimer.component";
import { DatenschutzComponent } from "./pages/datenschutz/datenschutz.component";
import { AgbComponent } from "./pages/agb/agb.component";

const routes: Routes = [
  {path: '', component: HomepageComponent},
  {path: 'forgott-password', component: ForgottPasswordComponent},
  {path: 'maklermandat', component: BrokerMandateFormComponent},
  {path: 'maklermandat/success/:type', component: SuccessComponent},
  {path: 'success/:type', component: SuccessComponent},
  {path: ':slug/maklermandat', component: BrokerMandateFormComponent, canActivate: [SlugValidationService] },
  {path: ':slug/maklermandat/:id', component: BrokerMandateFormComponent, canActivate: [SlugValidationService] },
  {path: 'login', component: LoginComponent},
  {path: 'account', component: AccountComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: ':slug/impressum', component: ImpressumComponent},
  {path: 'disclaimer', component: DisclaimerComponent},
  {path: ':slug/disclaimer', component: DisclaimerComponent},
  {path: 'datenschutz', component: DatenschutzComponent},
  {path: ':slug/datenschutz', component: DatenschutzComponent},
  {path: 'agb', component: AgbComponent},
  {path: ':slug/agb', component: AgbComponent},
  {path: 'account/:id', component: AccountComponent},
  //{path: ':slug', component: BrokerMandateFormComponent, canActivate: [SlugValidationService] },
  {path: ':slug', component: HomepageSubbrokerComponent, canActivate: [SlugValidationService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  providers: [SlugValidationService]
})
export class AppRoutingModule { }
