import { Component } from '@angular/core';
import { AfterViewInit, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ApiService } from "../../shared/services/api.service";
import { MandatFormService } from "../../shared/services/mandat-form.service";

@Component({
  selector: 'app-insurance-contracts',
  templateUrl: './insurance-contracts.component.html',
  styleUrls: ['./insurance-contracts.component.scss']
})
export class InsuranceContractsComponent implements OnInit, AfterViewInit, OnDestroy {


  @ViewChild('singleSelect', {static: true}) singleSelect: any;

  contracts: any;
  companies: any;
  companyCtrl: FormControl = new FormControl();
  companyFilterCtrl: FormControl = new FormControl('');
  filteredCompany: ReplaySubject<any> = new ReplaySubject<any>(1);
  _onDestroy = new Subject<void>();

  selectedCompany: any;
  selectedContracts: string[] = [];
  clientsContractList: any = [];
  anyOptionIsSelected = false;

  policeFormGroup: any;

  preSavedData: any;

  selectionList: {
    company: string;
    contract: string;
    police: string;
  }[] = []


  constructor(private _apiService: ApiService,
              private _mandatFormService: MandatFormService,
              private _formBuilder: FormBuilder) {
    this.policeFormGroup = this._formBuilder.group({
      police: ['',],
    });
  }

  getCompanies() {
    this._apiService.getList(1).subscribe({
      next: (data: any) => {
        this.contracts = data.contract_categories;
        this.companies = data.insurance_companies;
        this.loadInitialConsultantList();
        this.searchFieldValueChange();
      },
      error: (error: any) => {
        console.error('There was an error!', error);
      }
    });
  }

  loadInitialConsultantList() {
    this.filteredCompany.next(
      this.companies.slice()
    );
  }

  searchFieldValueChange() {
    this.companyFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCompanies();
      });
  }

  checkIfPrefilled(){
     this._mandatFormService.preSavedData.subscribe((response:any) => {
      this.preSavedData = response.police;
    })
  }

  ngOnInit() {
    this.getCompanies();
    this.checkIfPrefilled();
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredCompanies are loaded initially
   * setting the compareWith property to a comparison function
   * triggers initializing the selection according to the initial value of
   * the form control (i.e. _initializeSelection())
   * this needs to be done after the filteredCompanies are loaded initially
   * and after the mat-option elements are available
   */
  setInitialValue() {
    this.filteredCompany
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  filterCompanies() {
    if (!this.companies) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompany.next(this.companies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCompany.next(
      this.companies.filter((company: any) => company.name.toLowerCase().indexOf(search) > -1)
    );
  }

  onCompanySelectChange() {
    this.selectedContracts = [];
    this.selectionList = [];
  }

  onContractSelectChange(contractName: string) {
    this.anyOptionIsSelected = this.selectedContracts.length > 0;
    const contractExists = (value: any) => {
      let res = this.selectionList.filter((contract) =>
        contract.contract.indexOf(value) != -1
      )
      return res.length > 0
    }

    if (contractExists(contractName)) {
      this.removeContract(contractName);
    } else {
      this.addContractToList(contractName);
    }
  }

  addContractToList(contractName: string) {
    this.selectionList.push(
      {
        company: this.selectedCompany.name,
        contract: contractName,
        police: ''
      }
    )
  }

  removeContract(contractName: string) {
    this.selectionList.forEach((value, index) => {
      if (value.contract === contractName) {
        this.selectionList.splice(index, 1);
      }
    });
  }

  onPoliceChange(event: any, index: any) {
    this.selectionList[index].police = event.target.value;
  }

  addNewContract() {
    this.clientsContractList.push(this.selectionList);
    this.onCompanySelectChange();
    this.selectedCompany = '';
    this._mandatFormService.contractList = this.clientsContractList;
  }

  deleteFinal(index: any) {
    this.clientsContractList.splice(index, 1);
  }
}
