import {Injectable} from '@angular/core';
import {BinaryFontsService} from "./binary-fonts.service";
import * as pdfMake from 'pdfmake/build/pdfmake';
import {Margins, TDocumentDefinitions} from 'pdfmake/interfaces';
import {UnorderedListType} from 'pdfmake/interfaces';
import {MandatFormService} from "./mandat-form.service";
import {DomSanitizer} from '@angular/platform-browser';
import {Platform} from '@angular/cdk/platform';


@Injectable({
  providedIn: 'root'
})
export class PdfService {

  binaryPdf: any;
  pdfToOpen: any;
  mobiPdfToOpen: any;
  mobiBinaryPdf: any;
  agbPdfToOpen: any;
  agbBinaryPdf: any;

  constructor(private _binaryFontsService: BinaryFontsService,
              private _mandatFormService: MandatFormService,
              private sanitizer: DomSanitizer,
              private _platform: Platform) {
    (<any>pdfMake).vfs = this._binaryFontsService.fonts;
  }

  createPdf(img: any, client: any, date: any) {
    (pdfMake as any).fonts = {
      FiraSans: {
        normal: 'FiraSans-Light.ttf',
        bold: 'FiraSans-Regular.ttf',
        italics: 'FiraSans-Light.ttf',
        bolditalics: 'FiraSans-Light.ttf'
      },
    };

    (pdfMake as any).tableLayouts = {
      tableLayout: {
        hLineWidth: function (i: any, node: any) {
          return 0.5;
        },
        vLineWidth: function (i: any) {
          return 0;
        },
        hLineColor: function (i: any) {
          return 'black';
        },
      }
    };


    let docDefinition = {
      content: [
        {
          table: {
            widths: ['*'],
            style: 'header',
            body: [
              [
                {
                  border: [false, true, false, false],
                  text: 'Maklermandat / Vollmacht und Auftrag \n für die Verwaltung des Versicherungsportefeuilles',
                  fontSize: 12,
                  color: '#0075bf',
                  marginLeft: -5,
                  marginBottom: 11,
                  borderSize: [0.1, 0.1, 0.1, 0.1],
                  borderColor: ['#0075bf', '#0075bf', '#0075bf', '#0075bf'],
                  padding: 0,
                }
              ],
            ]
          },
        },
        {
          image: this._binaryFontsService.pdfLogo,
          width: 81.0850394,
          absolutePosition: {x: 492, y: 22.11024},
        },
        // Mandat
        {
          columns: [
            {
              width: 107,
              text: 'Zwischen'
            },
            {
              width: 'auto',
              text: this._mandatFormService.personalInformation.first_name + ' ' +
                this._mandatFormService.personalInformation.last_name + '\n' +
                this._mandatFormService.personalInformation.street + ', ' +
                this._mandatFormService.personalInformation.post_code + ' ' +
                this._mandatFormService.personalInformation.city + '\n' +
                this._mandatFormService.personalInformation.email + ', ' +
                this._mandatFormService.personalInformation.phone + '\n' +
                'nachstehend Mandant genannt'
            }
          ],
          style: 'columns',
          columnGap: 0
        },
        //BSC Broker
        {
          columns: [
            {
              width: 107,
              text: 'und der'
            },
            {
              // % width
              width: 'auto',
              text: 'BSC Broker Service Center GmbH \n Picardiestrasse 3A, 5040 Schöftland \n nachstehend BSC genannt'
            }
          ],
          style: 'columns',
          columnGap: 0
        },
        //Life Gate
        {
          columns: [
            {
              width: 107,
              text: 'in Zusammenarbeit \n mit dem Broker'
            },
            {
              // % width
              width: 'auto',
              text: 'Life Gate Consulting AG, Lindenhofweg 4, 8806 Bäch \n nachstehend Broker genannt'
            }
          ],
          style: 'columns',
          columnGap: 0
        },
        // Paragraph
        {
          text: 'Der Mandant erteilt dem Broker und der BSC die Vollmacht und den Auftrag, die bestehenden Versiche-rungspolicen über die BSC zu verwalten. Die Vereinbarung zur Verwaltung des Versicherungsportefeuilles umfasst im Weiteren folgende Dienstleistungen:',
          style: 'firstparagraph'
        },
        // List
        {
          markerColor: '#0075bf',
          style: 'list',
          type: 'square' as UnorderedListType,
          ul: [
            'Betreuung von bestehenden Versicherungsverträgen',
            'Überprüfung des Versicherungsbedarfs und des Versicherungsportefeuilles ',
            'Periodische Prüfung des Prämienangebotes auf dem Versicherungsmarkt',
            'Einholen von Offerten',
            'Übernahme von Erneuerungen und Neuabschlüssen',
            'Unterstützung in Schadenfällen inkl. Einsicht in das gesamte Dossier',
          ]
        },
        // Paragraph
        {
          text: 'Der Mandant bleibt Versicherungsnehmer und Prämienschuldner. Er unterzeichnet Versicherungsanträge selbst und nimmt Schadenzahlungen direkt entgegen. Der Mandant beauftragt die Gesellschaften, sämtliche Verträge und Bestände auf die BSC zu übertragen. Der Broker vertritt den Mandanten in allen übrigen Belan-gen gegenüber den Versicherungsgesellschaften.',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Die Akten (inkl. Korrespondenz wie Offerten, Policen, Prämienrechnungen, Mahnungen, Schadenkorres-pondenz und weitere) sind auf dem von der BSC, resp. dem Broker gewünschten Weg zuzustellen.',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Der Broker kann im Namen des Mandanten Verhandlungen führen sowie die erforderlichen Abklärungen und Vorkehrungen zur Vertragsgestaltung treffen.',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Allfällige Veränderungen der Tatbestände, welche auf die Versicherungsdeckung Einfluss haben, sind vom Mandanten umgehend an den Broker zu melden, damit die Versicherungsdeckung neu überprüft und angepasst werden kann (z.B. Tätigkeitsänderung oder Dienstleistungserweiterungen bei Firmen, etc.). Die Haftung der BSC ist gemäss AGB beschränkt.',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Diese Vereinbarung tritt mit der Unterzeichnung durch alle drei Parteien auf unbestimmte Zeit in Kraft und gilt bis auf deren Widerruf. Die unterzeichneten Parteien bestätigen, mit den Regelungen dieses Maklermandates einverstanden zu sein. Der Mandant bestätigt im Weiteren mit seiner Unterschrift, die Allgemeinen Geschäftsbedingungen des Brokers (AGB) erhalten zu haben und anerkennt diese. Zudem bestätigt der Mandant die Informationen bezüglich Art 45 VAG, den Datenschutzbestimmungen zur Kenntnis genommen zu haben und über die Höhe der Entschädigung informiert worden zu sein. Die neueste Version der AGBs kann der Mandant beim Broker bestellen oder auf der Webseite einsehen.',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Dieses Maklermandat hat ab Datum der Unterzeichnung Gültigkeit und kann jederzeit aufgelöst werden.',
          style: 'paragraph'
        },
        // Signature
        {
          style: 'table',
          layout: 'tableLayout',
          table: {
            widths: ['*', 20, '*'],
            heights: [45, 45, 45, 45],
            body: [
              [
                {
                  alignment: 'bottom',
                  border: [false, false, false, true],
                  marginTop: 35,
                  text: 'Dübendorf, ' + date
                },
                {
                  alignment: 'bottom',
                  border: [false, false, false, false],
                  text: ''
                },
                {
                  alignment: 'bottom',
                  border: [false, false, false, true],
                  stack: [
                    {
                      image: img,
                      alignment: 'center',
                      width: 120,
                      margin: [0, 0, 0, 0]
                    }
                  ]
                },
              ],
              [
                {
                  hLineWidth: 2,
                  border: [false, true, false, false],
                  text: 'Ort und Datum'
                },
                {
                  border: [false, false, false, false],
                  text: ''
                },
                {
                  border: [false, true, false, false],
                  text: 'Unterschrift Mandant'
                },
              ],
              [
                {
                  border: [false, true, false, false],
                  text: 'Ort und Datum'
                },
                {
                  border: [false, false, false, false],
                  text: ''
                },
                {
                  border: [false, true, false, false],
                  text: 'Unterschrift Broker'
                },
              ],
              [
                {
                  border: [false, true, false, false],
                  text: 'Ort und Datum'
                },
                {
                  border: [false, false, false, false],
                  text: ''
                },
                {
                  border: [false, true, false, false],
                  text: 'Unterschrift BSC Broker Service Center GmbH'
                },
              ],
            ]
          }
        },
        // Paragraph
        {
          absolutePosition: {x: 492, y: 770},
          table: {
            widths: [69],
            body: [
              [
                {
                  border: [false, true, false, false],
                  text: 'Seite 1 von 1\nVersion 26.01.2024',
                  fontSize: 8,
                  color: '#000000',
                  marginLeft: -5,
                  borderSize: [0.1, 0.1, 0.1, 0.1],
                  borderColor: ['#0075bf', '#0075bf', '#0075bf', '#0075bf'],
                  padding: 0,
                }
              ],
            ]
          },
        },
      ],
      defaultStyle: {
        font: 'FiraSans',
        fontSize: 8,
        color: 'black',
        lineHeight: 1.2,
      },
      styles: {
        paragraph: {
          margin: [0, 0, 0, 11] as Margins,
        },
        firstparagraph: {
          margin: [0, 11, 0, 0] as Margins,
        },
        list: {
          margin: [0, 0, 0, 11] as Margins,
        },
        columns: {
          margin: [0, 0, 0, 11] as Margins,
        }
      },
      pageMargins: [99.2126, 22.11024, 115.0315, 0] as Margins,
    };
    this.pdfToOpen = pdfMake.createPdf(docDefinition);
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getBase64((data) => {
      this.binaryPdf = data;
    });
  }


  createPdfMobiliar(img: any, date: any) {
    (pdfMake as any).fonts = {
      Arial: {
        normal: 'Arial-Regular.ttf',
        bold: 'Arial-Bold.ttf',
        italics: 'Arial-Regular.ttf',
        bolditalics: 'Arial-Regular.ttf'
      },
    };

    (pdfMake as any).tableLayouts = {
      tableLayout: {
        hLineWidth: function (i: any, node: any) {
          if (i === 0) {
            return 0;
          }
          return 0.5;
        },
        vLineWidth: function (i: any) {
          return 0;
        },
        hLineColor: function (i: any) {
          return 'black';
        },
      }
    };


    let docDefinition = {
      content: [
        {
          image: this._binaryFontsService.mobiliarLogo,
          width: 131.83,
          absolutePosition: {x: 402, y: 28.3},
        },
        // Paragraph
        {
          text: 'Verwaltung der Versicherungsverträge der Mobiliar',
          style: 'firstparagraph'
        },
        // Paragraph
        {
          text: 'Sehr geehrte Kundin, sehr geehrter Kunde',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Sie haben sich entschieden, die Betreuung Ihrer Versicherungsverträge künftig einem\n' +
            'Versicherungsmakler anzuvertrauen. Die von Ihnen gewählte Maklerfirma ist Partner der\n' +
            'Mobiliar und hat einen Zusammenarbeitsvertrag mit unserer Gesellschaft',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Da Sie weiterhin Versicherungsnehmer der Mobiliar bleiben, werden Ihre\n' +
            'Versicherungsverträge von der bisher zuständigen Generalagentur der Mobiliar verwaltet.',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Sie haben jedoch die Möglichkeit, eine andere Generalagentur zu wählen.',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Wir bitten Sie, diesen Entscheid gemeinsam mit Ihrer Maklerfirma zu treffen und danken\n' +
            'Ihnen für die Rücksendung dieses Formulars. ',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Freundliche Grüsse',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Schweizerische Mobiliar Versicherungsgesellschaft AG\n' +
            'Makler Service Center ',
          style: 'paragraph'
        },
        {
          layout: 'tableLayout',
          table: {
            widths: ['*', '*'],
            body: [
              [
                [
                  {
                    border: [false, false, false, false],
                    text: 'Christoph Kopp',
                    marginLeft: -5,
                    marginTop: 25,
                    padding: 0,
                  },
                  {
                    border: [false, false, false, false],
                    fontSize: 9,
                    marginLeft: -5,
                    marginBottom: 14,
                    text: 'Leiter Direktion Maklergeschäft'
                  }
                ],
                [
                  {
                    border: [false, false, false, false],
                    text: 'Stephan Schmucki',
                    marginLeft: -5,
                    marginTop: 25,
                    padding: 0,
                  },
                  {
                    border: [false, false, false, false],
                    fontSize: 9,
                    marginLeft: -5,
                    marginBottom: 14,
                    text: 'Leiter Makler Service Center'
                  }
                ],
              ],
            ]
          }
        },
        // Paragraph
        {
          text: 'Meine / unsere bei der Mobiliar platzierten Versicherungsverträge sollen',
          style: 'paragraph',
          marginTop: 27.7,
        },
        // Paragraph
        {
          text: 'auf der bisher zuständigen Generalagentur der Mobiliar weitergeführt werden.',
          style: 'paragraph',
          marginLeft: 34.66,
        },
        //Icon
        {
          image: this._binaryFontsService.uncheckdIcon,
          width: 7.9,
          marginTop: -23,
          marginLeft: 17.9,
        },
        // Paragraph
        {
          text: 'auf der Generalagentur in Dietikon Zürich geführt werden.',
          style: 'paragraph',
          marginLeft: 34.66,
          marginTop: 23,
        },
        //Icon
        {
          image: this._binaryFontsService.checkedIcon,
          width: 8.5,
          marginTop: -23,
          marginLeft: 17.9,
        },
        // Paragraph
        {
          text: 'Datum, Stempel, Unterschrift',
          style: 'paragraph',
          marginTop: 27.55,
        },
        // Paragraph
        {
          columns: [
            {
              text: 'Versicherungsnehmer',
              bold: true,
            },
            {
              text: 'BSC Broker Service Center GmbH',
              bold: true,
            },
          ]
        },
        //Signature
        {
          image: img,
          width: 200,
          marginLeft: -10,
        },
        // Paragraph
        {
          text: date,
        },
      ],
      defaultStyle: {
        font: 'Arial',
        fontSize: 11,
        color: 'black',
        lineHeight: 1.2,
      },
      styles: {
        paragraph: {
          margin: [0, 0, 0, 11] as Margins,
        },
        firstparagraph: {
          fontSize: 11,
          bold: true,
          margin: [0, 0, 0, 21] as Margins,
        },
      },
      pageMargins: [85.5, 141.17, 74, 0] as Margins,
    };
    this.mobiPdfToOpen = pdfMake.createPdf(docDefinition);
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getBase64((data) => {
      this.mobiBinaryPdf = data;
    });
  }

  createPdfAGB(img: any, date: any) {
    (pdfMake as any).fonts = {
      Arial: {
        normal: 'Arial-Regular.ttf',
        bold: 'Arial-Bold.ttf',
        italics: 'Arial-Regular.ttf',
        bolditalics: 'Arial-Regular.ttf'
      },
    };

    (pdfMake as any).tableLayouts = {
      tableLayout: {
        hLineWidth: function (i: any, node: any) {
          if (i === 0) {
            return 0;
          }
          return 0.5;
        },
        vLineWidth: function (i: any) {
          return 0;
        },
        hLineColor: function (i: any) {
          return 'black';
        },
      }
    };


    let docDefinition = {

      footer: function(currentPage: number, pageCount: number) {
        return {
          columns: [
            { text: 'Version 26.01.2024', alignment: 'right', fontSize: 7 }
          ],
          margin: [40, 0]
        };
      },
      content: [
        {
          text: 'Life Gate Consulting AG',
          style: 'header'
        },
        // Paragraph
        {
          text: 'Allgemeine Geschäftsbedingungen AGB',
          style: 'header'
        },
        // Header
        {
          text: 'Zweck und Rechtsstellung der Gesellschaft',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Die Life Gate Consulting AG – nachfolgend Life Gate Consulting AG genannt - ist eine Life Gate Consulting AG mit Sitz in (Sitz der Gesellschaft). Der Gesellschaftszweck besteht in der Erbringung von Dienstleistungen als ungebundener Versicherungsvermittler. Ein Auftrag für Mandanten der Life Gate Consulting AG wird mittels Maklermandat, welches durch alle Beteiligten unterzeichnet wird, begründet.',
          style: 'paragraph'
        },
        // Header
        {
          text: 'Informationspflichten an die Mandanten (gem. Art. 45 VAG)',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Ihre Ansprechpartnerin',
          style: 'subheader'
        },
        // Header
        {
          text: 'Life Gate Consulting AG ist eine ungebundene Vermittlerin gemäss Art. 40 Abs. 2 VAG und eingetragen im von der Eidgenössischen Finanzmarktaufsicht FINMA geführten Register für Versicherungsvermittler unter der Nummer F01097803',
          style: 'paragraph'
        },
        // subheader
        {
          text: 'Vertragsbeziehungen',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Als unabhängiger Versicherungsvermittler arbeitet Life Gate Consulting AG je nach Versicherungsbedarf der Mandanten mit verschiedenen Anbietern von Versicherungsdienstleistungen in allen Versicherungszweigen zusammen. Mit den Versicherungsunternehmen bestehen Zusammenarbeitsverträge, welche Form und Inhalt der Zusammenarbeit regeln. Sie enthalten keine Regelungen, welche die Unabhängigkeit von BSC einschränken könnten. \n',
          style: 'paragraph'
        },
        // Header
        {
          text: 'Aus- und Weiterbildung  ',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Gerne wird auf Wunsch eine Übersicht der von unseren Versicherungsvermittlern/-vermittlerinnen absolvierten Aus- und Weiterbildungen zur Verfügung gestellt. Bitte wenden Sie sich bei Bedarf an Life Gate Consulting AG bzw. BSC.',
          style: 'paragraph'
        },
        // Header
        {
          text: 'Haftung',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Die im Brokermandat als Beauftragte genannte Partei gilt als die Person, die für Nachlässigkeit, Fehler oder unrichtige Auskünfte im Zusammenhang mit ihrer Vermittlungstätigkeit haftbar gemacht werden kann.',
          style: 'paragraph'
        },
        // Header
        {
          text: 'Datenschutz',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Um die mit der Auftraggeberin vereinbarten Dienstleistungen erbringen zu können, werden die dafür notwendigen Personendaten (bspw. Name, Adresse, Telefonnummer, AHV-Nummer, Angaben über vergangene Schadenfälle, Gesundheitszustand, Lohnangaben etc.) bearbeitet und an Versicherungsunternehmen und/oder Vorsorgeeinrichtungen übermittelt. Dabei werden die anwendbaren Datenschutzbestimmungen eingehalten. Die Daten werden bei der BSC physisch und/oder in elektronischer Form aufbewahrt. Die Zwecke der Datenbearbeitung, die Aufbewahrung der Daten, die Übermittlung der Daten und weitere wichtige Informationen finden sich in der Datenschutzerklärung: www.maklermandat.ch/datenschutz',
          style: 'paragraph'
        },
        // Header
        {
          text: 'Haftung',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Life Gate Consulting AG haftet für grobfahrlässig oder absichtlich verursachte Schäden. Die Haftung für leichtfahrlässig verursachte Schäden ist im Rahmen des gesetzlich zulässigen Umfangs ausgeschlossen. Life Gate Consulting AG verfügt über die von der zuständigen Aufsichtsbehörde für ungebundene Versicherungsvermittler vorgeschriebene Berufshaftpflichtversicherung. Sind Unterlagen oder Informationen des Mandanten unvollständig oder mangelhaft und entsteht direkt oder indirekt daraus ein Schaden, haftet die Life Gate Consulting AG nicht dafür.',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Die BSC Broker Service Center GmbH (BSC) erbringt Dienstleistungen für die Life Gate Consulting AG. Diese umfasst einzig das Produktemarketing, Erarbeiten von allgemeinen Vergleichen, die Offertkoordination, die Triage der Korrespondenz, die Provisionsabrechnung und weitere von uns delegierten Leistungen. Mit Ausnahme der soeben erwähnten Tätigkeiten von BSC erbringt Life Gate Consulting AG sämtliche Tätigkeiten im Rahmen des Riskmanagements, der Beratung der Mandanten. Entstehen aus der Tätigkeit als Versicherungsbroker Schadenersatzansprüche, haftet alleine die Life Gate Consulting AG. Die Haftung der BSC ist, soweit gesetzlich zulässig, gegenüber dem Mandanten ausgeschlossen. Mit der Unterschrift auf dem Maklermandat erklärt sich der Mandant als damit einverstanden.',
          style: 'paragraph'
        },
        // Header
        {
          text: 'Datenschutz / Geheimhaltung',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Die MitarbeiterInnen der BSC und der Life Gate Consulting AG unterliegen der Schweigepflicht. Die Datenbearbeitung kann verschiedenartig durchgeführt werden. Diese ist in der Datenschutzerklärung festgehalten. Die Datenschutzerklärung ist auf der Webseite der BSC (brokerservice.ch) und der Life Gate Consulting AG (Webseite des Co-Brokers.ch) abrufbar. Die Daten werden in Papierform und/oder elektronisch aufbewahrt. Der Mandant erklärt sich mit dieser Datenschutzerklärung einverstanden. Bei Firmenkunden informieren die verantwortlichen alle Personen, bei denen Personendaten bearbeitet werden (z.B. BVG, KTG mit Fixlöhnen, usw.), wo sie die Datenschutzerklärung der Life Gate Consulting AG und der BSC finden und an wen sie sich bei Fragen wenden können. \n',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Entschädigung',
          style: 'subheader',
          pageBreak: 'before'
        },
        // Paragraph
        {
          text: 'Honorar\n',
        },
        // Paragraph
        {
          text: 'Der Mandant schuldet der Life Gate Consulting AG für vereinbarte oder in seinem Interesse erbrachten Dienstleistungen Honorare und Nebenkosten in absteigender Reihenfolge gemäss:\n',
          style: 'paragraph',
        },
        {
          markerColor: '#000000',
          style: 'list',
          type: 'lower-alpha',
          ol: [
            'Individuell vereinbartem Honorar',
            'Preisliste der Life Gate Consulting AG',
            'Nach Aufwand zu einem Stundensatz von CHF 250.- exkl. MWST soweit nicht durch die Entschädi-gung Dritter gedeckt',
            'Ohne Abrechnung, das heisst, die Life Gate Consulting AG vereinnahmt die Entschädigung der Versi-cherungsunternehmen, Stiftungen und weiteren für die erbrachten Dienstleistungen. Der Mandant verzichtet ausdrücklich auf Herausgabe solcher Entschädigungen.'
          ]
        },
        // Paragraph
        {
          text: '\nEntschädigungsvereinbarungen zwischen dem Mandanten und der Life Gate Consulting AG betreffen die BSC Broker Service Center GmbH nicht. Ausgenommen davon sind durch die BSC Broker Service Center GmbH schriftlich bestätigte Änderungen. Life Gate Consulting AG verpflichtet sich, die Offenlegung der Entschädi-gung nach Art. 45b VAG gegenüber den Mandanten vorzunehmen.',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Entschädigungen von Versicherungsunternehmen, Stiftungen und weiteren Gesellschaften',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Der Mandant ist sich bewusst und ausdrücklich darüber informiert, dass die Life Gate Consulting AG im Rahmen seiner Tätigkeit als Broker oder bei Gelegenheit der Auftragserfüllung Entschädigungen (z.B. Provisionen, Courtagen, usw.) von Dritten, insbesondere von Versicherungsgesellschaften, erhält oder erhalten könnte. Falls die Life Gate Consulting AG solche Entschädigungen erhält, welche es gemäss jeweils aktueller Rechtsprechung oder gemäss jeweils anwendbaren gesetzlichen Vorschriften dem Mandanten abliefern müsste, so ist der Mandant ausdrücklich damit einverstanden, dass die Life Gate Consulting AG diese Entschädigung zusätzlich für seine Tätigkeit für den Mandanten erhält. Der Mandant erklärt mit der Unterzeichnung des Maklermandates ausdrücklich, auf die Herausgabe dieser Entschädigung zu verzichten. Wird nichts anderes vereinbart, gilt die Abrechnung nach d. Wünscht der Mandant im Nachhinein eine andere \n' +
            'Abrechnungsart als vereinbart, verzichtet der Mandant wie beschrieben auf eine rückwirkende Herausgabe der Entschädigung Dritter. In der Beilage zu den Allgemeinen Geschäftsbedingungen hat der Mandant eine Liste mit den ungefähren Entschädigungssätzen der Versicherungsgesellschaften bekommen. Der Mandant wurde im Zusammenhang mit der Beratung resp. einem Abschluss über die Entschädigung informiert. Er hat zudem das Recht, jederzeit Informationen zu den genauen Entschädigungen zu bekommen. Mit dieser Liste ist dem Kunden bekannt, auf welche Entschädigungen er verzichtet.',
          style: 'paragraph'
        },
        // Header
        {
          text: 'Dienstleistungen',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Die Life Gate Consulting AG berät den Mandanten in Versicherungsangelegenheiten. Dies beinhaltet insbesondere die Betreuung und Bewirtschaftung aller bestehenden Versicherungsverträge, Überprüfung des Versicherungsbedarfs und des Versicherungsportefeuilles, periodische Prüfung des Prämienangebotes auf dem Versicherungsmarkt, Einholen von Offerten bei verschiedenen Versicherungsgesellschaften, Erneuerungen und Neuabschlüsse von Versicherungspolicen und Unterstützung im Schadenfall. Für weitergehende Auftragsarbeiten wird ein Beratungshonorar gemäss gegenseitiger Absprache verrechnet.',
          style: 'paragraph'
        },
        // Header
        {
          text: 'Mandantenangaben / Legitimationsprüfung',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Der Mandant verpflichtet sich, bei der Aufnahme eines Versicherungsantrages alle Informationen betreffend den Personen- und Sachinformationen wahrheitsgetreu an die Life Gate Consulting AG an- resp. weiterzugeben. Insbesondere ist die Korrektheit der Mandantenaussagen bei Gesundheitsfragen unumgänglich. Werden Tatbestände oder Krankheiten verschwiegen, kann dies zu einer Anzeigepflichtverletzung führen. Eine Anzeigepflichtverletzung kann nach Versicherungsvertragsgesetz zu einer Kündigung des Versicherungsvertrags durch das Versicherungsunternehmen führen. Die Life Gate Consulting AG verpflichtet sich zur gewissenhaften Prüfung der Legitimation des Kunden und der Bevollmächtigten. Weiter verpflichtet sich Life Gate Consulting AG, den Kunden über mögliche Folgen von Anzeigepflichtverletzungen aufzuklären. Den aus dem Nichterkennen von Legitimationsmängeln und Fälschungen oder Täuschungen entstandenen Schaden, trägt der Kunde, sofern die Life Gate Consulting AG die geschäftsübliche Sorgfalt angewendet hat.',
          style: 'paragraph'
        },

        // Header
        {
          text: 'Übermittlungsfehler',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Den aus der Benützung von Post, Telefax, Telefon, E-Mail und anderen Übermittlungs- oder Transportarten entstehenden Schaden, wie z.B. aus Verlust, Verspätung, Missverständnissen, Verstümmelungen oder Doppelausfertigungen, trägt der Mandant, sofern die Life Gate Consulting AG die geschäftsübliche Sorgfalt angewendet hat.',
          style: 'paragraph'
        },

        // Header
        {
          text: 'Mitwirkungspflicht des Mandanten',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Der Mandant verpflichtet sich zur Mitwirkungspflicht. Ändert sich eine Gefahrstatsache (z.B. Standort, Tätigkeit, Versicherungssumme, usw.) verpflichtet sich der Mandant dies der Life Gate Consulting AG umgehend mitzuteilen. Dasselbe gilt für neue Gefahrstatsachen. Stellt der Mandant Fehler bei einer Versicherungspolice fest, ist dies der Life Gate Consulting AG umgehend mitzuteilen. Ergeben sich Schäden aus der Unterlassung des Mandanten, ist die Haftung von Life Gate Consulting AG soweit gesetzlich zulässig ausgeschlossen.\n',
          style: 'paragraph'
        },

        // Header
        {
          text: 'Copyright',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Die von der Life Gate Consulting AG abgegebenen Auswertungsunterlagen und Konzepte an die Kunden unterstehen einem Copyright, welches die ideellen und materiellen Interessen des Urhebers (BSC/Life Gate Consulting AG) an seinem geistigen Eigentum schützt.',
          style: 'paragraph'
        },


        // Header
        {
          text: 'Sonstiges',
          style: 'subheader',
          pageBreak: 'before'
        },
        // Paragraph
        {
          text: 'Die Life Gate Consulting AG behält sich das Recht vor, die AGB jederzeit zu ändern und die gesetzlichen Bestimmungen anzupassen. Die aktuellen AGBs können auf der Webseite der Life Gate Consulting AG (Webseite des Co-Brokers.ch) abgerufen werden.',
          style: 'paragraph'
        },


        // Header
        {
          text: 'Salvatorische Klausel ',
          style: 'subheader',
        },
        // Paragraph
        {
          text: 'Sollte eine der Bestimmungen dieser Allgemeinen Geschäftsbedingungen ungültig, nichtig oder nicht durchsetzbar sein, so werden die Gültigkeit und Durchsetzbarkeit der übrigen Bestimmungen hiervon nicht berührt.',
          style: 'paragraph'
        },

        // Header
        {
          text: 'Anwendbares Recht und Gerichtsstand',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Sollte eine der Bestimmungen dieser Allgemeinen Geschäftsbedingungen ungültig, nichtig oder nicht durchsetzbar sein, so werden die Gültigkeit und Durchsetzbarkeit der übrigen Bestimmungen hiervon nicht berührt.Bei Streitbarkeit zwischen dem Mandanten und der Life Gate Consulting AG gilt schweizerisches Recht. Ge-richtsstand ist der Sitz der Life Gate Consulting AG. ',
          style: 'paragraph'
        },

        // Header
        {
          text: 'Offenlegung der Entschädigungen nach Art. 45b Versicherungsaufsichtsgesetz',
          style: 'subheader'
        },
        // Paragraph
        {
          text: 'Für die Erbringung der Dienstleistungen erhält Life Gate Consulting AG von den Versicherungsunternehmen oder Dritten eine marktübliche Courtage, welche in den offerierten Prämien enthalten ist. Die Entschädigungen der Versicherungsunternehmen an Life Gate Consulting AG sind abhängig von der jeweiligen Prämienhöhe und der Versicherungsbranche. Life Gate Consulting AG hat mit den Versicherungsunternehmen oder Dritten folgende Courtagesätze vereinbart:',
          style: 'paragraph'
        },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            body: [
              [{text: 'Branche', bold: true}, {text: 'Satz in % der Nettoprämie', bold: true}, {text: 'Normaler Satz', bold: true}],
              ['Sachversicherungen', '7.5 bis 15%', '15%'],
              ['Haftpflichtversicherungen', '7.5 bis 15%', '15%'],
              ['Rechtsschutzversicherungen', '15%', '15%'],
              [
                {
                  stack: [
                    'Motorfahrzeugversicherungen:',
                    { text: '– Haftpflicht', margin: [10, 5, 0, 0] }, // margin: [left, top, right, bottom]
                    { text: '– Teilkasko', margin: [10, 0, 0, 0] },
                    { text: '– Kollisionskasko', margin: [10, 0, 0, 0] },
                    { text: '– Unfall', margin: [10, 0, 0, 5] },
                  ]
                },
                {
                  stack: [
                    ' ',
                    { text: '4 bis 10%', margin: [0, 5, 0, 0] }, // margin: [left, top, right, bottom]
                    { text: '7 bis 15%', margin: [0, 0, 0, 0] },
                    { text: '7 bis 12%', margin: [0, 0, 0, 0] },
                    { text: '7 bis 15%', margin: [0, 0, 0, 5] },
                  ]
                },
                {
                  stack: [
                    ' ',
                    { text: '4%', margin: [0, 5, 0, 0] }, // margin: [left, top, right, bottom]
                    { text: '15%', margin: [0, 0, 0, 0] },
                    { text: '12%', margin: [0, 0, 0, 0] },
                    { text: '15%', margin: [0, 0, 0, 5] },
                  ]
                },
              ],
              ['Unfallversicherungen', '3 bis 7%', '5%'],
              ['Unfall Zusatzversicherungen', '15 bis 17.5%', '15%'],
              ['Krankentaggeldversicherungen', '7.5 bis 10%', '7.5%'],
              ['Kollektivlebensversicherungen', '0.5 bis 1.8%', '1%'],
              ['Einzellebensversicherungen', '0.7 bis 5.3% der Produktionssumme*', ''],
              [
                {
                  stack: [
                    'Krankenkassen:',
                    { text: '– KVG (Grundversicherung)', margin: [10, 5, 0, 0] }, // margin: [left, top, right, bottom]
                    { text: '– VVG (Zusatzversicherungen)', margin: [10, 0, 0, 5] },
                  ]
                },
                {
                  stack: [
                    ' ',
                    { text: 'CHF 0.00 bis CHF 70.00', margin: [0, 5, 0, 0] }, // margin: [left, top, right, bottom]
                    { text: '3 bis 12 Monatsprämien', margin: [0, 0, 0, 5] },
                  ]
                },
                {
                  stack: [
                    ' ',
                    { text: 'CHF 70.00', margin: [0, 5, 0, 0] }, // margin: [left, top, right, bottom]
                    { text: '12 Monatsprämien', margin: [0, 0, 0, 5] },
                  ]
                },
              ],
            ]
          },
          layout: 'noBorders'
        },
        // Paragraph
        {
          text: '\n* Die Produktionssumme setzt sich aus den einbezahlten Nettoprämien (ohne Stempelsteuer), der Laufzeit und des produktespezifischen Koeffizienten zusammen. Produktionsspezifische Koeffizienten sind zwischen 10 und 120%.',
          style: 'paragraph'
        },
        // Paragraph
        {
          text: 'Der Mandant hat die allgemeinen Geschäftsbedingungen mit der Information nach Art. 45 VAG und Offenle-gung der Entschädigungen nach Art. 45b VAG ausgehändigt bekommen und erklärt sich mit diesen einver-standen.',
          style: 'paragraph'
        },
        // Signature
        {
          style: 'table',
          layout: 'tableLayout',
          table: {
            widths: ['*', 20, '*'],
            heights: [45, 45, 45, 45],
            body: [
              [
                {
                  alignment: 'bottom',
                  border: [false, false, false, true],
                  marginTop: 35,
                  text: 'Dübendorf, ' + date
                },
                {
                  alignment: 'bottom',
                  border: [false, false, false, false],
                  text: ''
                },
                {
                  alignment: 'bottom',
                  border: [false, false, false, true],
                  stack: [
                    {
                      image: img,
                      alignment: 'center',
                      width: 120,
                      margin: [0, 0, 0, 0]
                    }
                  ]
                },
              ],
              [
                {
                  hLineWidth: 2,
                  border: [false, true, false, false],
                  text: 'Ort und Datum'
                },
                {
                  border: [false, false, false, false],
                  text: ''
                },
                {
                  border: [false, true, false, false],
                  text: 'Unterschrift Mandant'
                },
              ],
            ]
          }
        },
      ],
      defaultStyle: {
        font: 'Arial',
        fontSize: 8.5,
        color: 'black',
        lineHeight: 1.2,
      },
      styles: {
        header: {
          bold: true,
          fontSize: 15,
          marginBottom: 12,
        },
        subheader: {
          bold: true,
          fontSize: 9,
        },
        paragraph: {
          marginBottom: 12,
        },
      },
      pageMargins: [56, 28, 28, 28] as Margins,
    } as TDocumentDefinitions;
    this.agbPdfToOpen = pdfMake.createPdf(docDefinition);
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getBase64((data) => {
      this.agbBinaryPdf = data;
    });
  }

  viewPdf(pdf: any) {
    if (this._platform.ANDROID) {
      pdf.download();
    } else {
      pdf.open();
    }
  }

}
