import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../shared/services/api.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { MandatFormService } from "../../shared/services/mandat-form.service";

@Component({
  selector: 'app-account-manager',
  templateUrl: './account-manager.component.html',
  styleUrls: ['./account-manager.component.scss']
})
export class AccountManagerComponent {

  @ViewChild('singleSelect', {static: true}) singleSelect: any;

  emailRegex: any;
  accountManagerFormGroup: any;
  slug: any;
  company: any;
  selected = 1;
  consultants: any;
  consultantCtrl: FormControl = new FormControl();
  consultantFilterCtrl: FormControl = new FormControl('');
  filteredConsultants: ReplaySubject<any> = new ReplaySubject<any>(1);
  _onDestroy = new Subject<void>();
  preSavedData: any;

  constructor(private _route: ActivatedRoute,
              private _mandatFormService: MandatFormService,
              private _apiService: ApiService,
              private _formBuilder: FormBuilder) {

    this.emailRegex = this._mandatFormService.emailRegex;
    this.accountManagerFormGroup = this._formBuilder.group({
      companyName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      street: ['', Validators.required],
      postCode: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      city: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      phone: ['', Validators.required],
    });
  }

  getSlug() {
    this.slug = this._route.snapshot.paramMap.get('slug');
    if (this.slug) {
      this.getConsultants();
    }
  }

  getConsultants() {
    this._apiService.getAccountManagerList(this.slug).subscribe({
      next: (data: any) => {
        this.company = data;
        this.consultants = data.members;
        this.loadInitialConsultantList();
        this.searchFieldValueChange();
        this._mandatFormService.accountId = data.id;
        console.log(this._mandatFormService.accountId);
      },
      error: (error: any) => {
        console.error('There was an error!', error);
      }
    });
  }

  loadInitialConsultantList() {
    this.filteredConsultants.next(
      this.consultants.slice()
    );
  }

  searchFieldValueChange() {
    this.consultantFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterConsultants();
      });
  }

  checkIfPrefilled(){
     this._mandatFormService.preSavedData.subscribe((response:any) => {
      this.preSavedData = response.mandate_manager;
    })
  }

  ngOnInit() {
    this.getSlug();
    this.checkIfPrefilled()
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredConsultants are loaded initially
   * setting the compareWith property to a comparison function
   * triggers initializing the selection according to the initial value of
   * the form control (i.e. _initializeSelection())
   * this needs to be done after the filteredConsultants are loaded initially
   * and after the mat-option elements are available
   */
  setInitialValue() {
    this.filteredConsultants
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
        }
      });
  }

  filterConsultants() {
    if (!this.consultants) {
      return;
    }
    // get the search keyword
    let search = this.consultantFilterCtrl.value;
    if (!search) {
      this.filteredConsultants.next(this.consultants.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the consultants
    this.filteredConsultants.next(
      this.consultants.filter((consultant: any) => {
        return consultant.first_name.toLowerCase().indexOf(search) > -1 ||
          consultant.last_name.toLowerCase().indexOf(search) > -1;
      })
    );
  }

}
