import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrokerMandateFormComponent } from './broker-mandate-form/broker-mandate-form.component';
import { InsuranceContractsComponent } from './broker-mandate-form/insurance-contracts/insurance-contracts.component';
import { SignatureComponent } from './broker-mandate-form/signature/signature.component';
import { LoginComponent } from './account/login/login.component';
import { AccountComponent } from './account/account/account.component';
import { PersonalInformationComponent } from './broker-mandate-form/personal-information/personal-information.component';
import { AccountManagerComponent } from './broker-mandate-form/account-manager/account-manager.component';
import { SignatureDialogComponent } from './shared/signature-dialog/signature-dialog.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { HomepageSubbrokerComponent } from './pages/homepage-subbroker/homepage-subbroker.component';




/*Angular Material*/
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ForgottPasswordComponent } from './account/forgott-password/forgott-password.component';
import { SuccessComponent } from './pages/success/success.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { AgbComponent } from './pages/agb/agb.component';

let imports = [
  BrowserModule,
  AppRoutingModule,
  HttpClientModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  MatStepperModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatSelectModule,
  MatCheckboxModule,
  MatListModule,
  MatChipsModule,
  MatDialogModule,
  NgxMatSelectSearchModule,
  MatProgressBarModule,
  MatPaginatorModule,
  MatExpansionModule,
  MatMenuModule,
  MatSnackBarModule
];

@NgModule({
  declarations: [
    AppComponent,
    BrokerMandateFormComponent,
    InsuranceContractsComponent,
    SignatureComponent,
    PersonalInformationComponent,
    AccountManagerComponent,
    SignatureDialogComponent,
    LoginComponent,
    AccountComponent,
    HomepageComponent,
    HeaderComponent,
    HomepageSubbrokerComponent,
    ForgottPasswordComponent,
    SuccessComponent,
    ImpressumComponent,
    DisclaimerComponent,
    DatenschutzComponent,
    AgbComponent,
  ],
  imports: imports,
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }
}
