import { AfterViewInit, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog,  } from "@angular/material/dialog";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss']
})
export class SignatureDialogComponent implements AfterViewInit {

  @Input() name: string = '';
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement> | any;
  ctx: any = CanvasRenderingContext2D;
  dimension: any = {width: 700, height: 350};
  possibleSave = false;
  testwidth: any = 700;
  testheight: any = 280;
  private isWriting: BehaviorSubject<boolean>;

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<SignatureDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isWriting = new BehaviorSubject<boolean>(false);

    if (window.innerWidth < 700) {
      this.testwidth = window.innerWidth - 60;
      this.testheight = (window.innerWidth - 60) / 2;
    }
  }

  checkIfWriting(): Observable<boolean> {
    return this.isWriting.asObservable();
  }

  ngOnInit() {
    this.checkIfWriting().subscribe((value) => {
      if (!value && this.possibleSave) {
        this.saveSignature();
      }
    });

    this.data.img = '';
  }

  ngAfterViewInit() {

    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.ctx.fillStyle = 'rgba(255, 255, 255, 0)';
    this.ctx.fillRect(0, 0, this.testwidth, this.testheight);

    // Handle Mouse events
    const mouseDownStream = fromEvent(this.canvas.nativeElement, 'mousedown');
    const mouseMoveStream = fromEvent(this.canvas.nativeElement, 'mousemove');
    const mouseUpStream = fromEvent(window, 'mouseup')

    mouseDownStream.pipe(map((e) => this.startDraw(e))).subscribe();
    mouseMoveStream.pipe(map((e) => this.keepDraw(e))).subscribe();
    mouseUpStream.pipe(map(() => (this.isWriting.next(false)))).subscribe();

    // Handle Touch events
    const touchDownStream = fromEvent(this.canvas.nativeElement, 'touchstart');
    const touchMoveStream = fromEvent(this.canvas.nativeElement, 'touchmove');
    const touchUpStream = fromEvent(window, 'touchend');

    touchDownStream.pipe(map((e) => this.startDraw(e))).subscribe();
    touchMoveStream.pipe(map((e) => this.keepDraw(e))).subscribe();
    touchUpStream.pipe(map(() => (this.isWriting.next(false)))).subscribe();
  }

  startDraw(e: any) {
    this.ctx.beginPath();
    this.ctx.strokeStyle = 'black';
    this.ctx.lineWidth = 2;
    this.ctx.lineJoin = 'round';
    const location = this.getLocation(e);
    this.ctx.moveTo(location.X, location.Y);
    this.isWriting.next(true);
    this.possibleSave = true;
  }

  keepDraw(e: any) {
    if (!this.isWriting.value) return;
    const location = this.getLocation(e);
    this.ctx.lineTo(location.X, location.Y);
    this.ctx.stroke();
  }

  getLocation(e: any) {
    var location = {X: 0, Y: 0};
    if (e instanceof MouseEvent) {
      location.X = e.offsetX;
      location.Y = e.offsetY;
    } else {
      var dimensions = e.target.getBoundingClientRect();

      location.X = e.touches[0].clientX - dimensions.left;
      location.Y = e.touches[0].clientY - dimensions.top;
    }
    return location;
  }

  onClearClick() {
    this.possibleSave = false;
    this.ctx.clearRect(0, 0, this.testwidth, this.testwidth);
    this.ctx.fillStyle = 'rgba(255, 255, 255, 0)';
    this.ctx.fillRect(0, 0, this.testwidth, this.testwidth);
    this.data.img = '';
  }

  saveSignature() {
    this.data.img = this.canvas.nativeElement.toDataURL('image/png');
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
