import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ApiService } from "./api.service";
import { ThemeService } from "./theme.service";

@Injectable({
  providedIn: 'root'
})
export class SlugValidationService implements CanActivate {

  constructor(private _router: Router,
              private _apiService: ApiService,
              private _themeService: ThemeService) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let slug = route.paramMap.get('slug');

    if (slug) {
      const data = await this._apiService.getAccountManagerList(slug).toPromise();
      this._themeService.updateTheme(slug);
      return true;
    }
    this._router.navigate(['']);
    return false;
  }


}
