import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiService } from "../../shared/services/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthorizationService } from "../../shared/services/authorization.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { PdfService } from "../../shared/services/pdf.service";
import { DatePipe } from '@angular/common';
import { MatSnackBar } from "@angular/material/snack-bar";
import { EmailService } from "../../shared/services/email.service";
import { LoadingService } from "../../shared/services/loading.service";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: any;

  accounts: any;
  brokerAccount: any;
  accountId: any;
  isBrokerAccount = false;
  user: any;

  submissions: any;
  subscriptions: any[] = [];
  dataSource: any;
  totalSubmissions: any;
  pageSize = 10;
  isVisible = false;
  visibilityId: any;
  isLoading = false;

  constructor(private http: HttpClient,
              private route: ActivatedRoute,
              private _apiService: ApiService,
              private _authService: AuthorizationService,
              private _router: Router,
              private _pdfService: PdfService,
              private _emailService: EmailService,
              private _loadingService: LoadingService) {
  }

  ngOnInit() {
    this.getAccountDetails();
    this.checkIfLoading();
  }

  checkIfLoading() {
    this._loadingService.isLoading.subscribe((response: any) => {
      this.isLoading = response;
    })
  }

  getAccountDetails() {
    this._apiService.getAccount(this._authService.authHeaders()).subscribe({
      next: (response: any) => {
        this.user = response.user;
        this.accounts = response.accounts;

        this.checkIfBroker();
      },
      error: (error: any) => {
        console.error('There was an error!', error);
        this._router.navigate(['/login']);
      }
    });
  }

  getMandateList(id: any, page: number,) {
    this.checkVisibility(id);
    this.accountId = id;
    this.visibilityId = id;
    this.isBrokerAccount = false;
    this._apiService.getMandateList(id, this._authService.authHeaders(), page, this.pageSize)
      .subscribe({
        next: (response: any) => {
          this.submissions = response.results;
          this.dataSource = new MatTableDataSource(response.results);
          this.totalSubmissions = response.count;
          this.dataSource.paginator = this.paginator;
        },
        error: (error: any) => {
          console.error('There was an error!', error);
        }
      });
  }

  checkVisibility(id: any){
    this.isVisible = true;
    /*
    if( !this.isVisible || !this.visibilityId || this.visibilityId != id ){
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }

     */
  }


  checkIfBroker() {
    this.accounts.forEach((account: any) => {
      if (account.is_broker) {
        this.brokerAccount = {
          "name" : account.name + ' – Broker',
          "id" : account.id,
          "slug": account.slug,
        }
      }
    })
  }

  getBrokerList(id: any, page: number,) {
    this.checkVisibility(id + 'Broker');
    this.accountId = id;
    this.visibilityId = id + 'Broker';
    this.isBrokerAccount = true;
    this._apiService.getBrokerList(id, this._authService.authHeaders(), page, this.pageSize)
      .subscribe({
        next: (response: any) => {
          this.submissions = response.results;
          this.dataSource = new MatTableDataSource(response.results);
          this.totalSubmissions = response.count;
          this.dataSource.paginator = this.paginator;
        },
        error: (error: any) => {
          console.error('There was an error!', error);
        }
      });
  }

  openPdf(data: any) {
    const base64String = data;
    const decodedData = atob(base64String);
    const array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      array[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([array], {type: 'application/pdf'});
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl);
  }

  sendLink(submission:any){
    let account = this.accounts.find((account:any) => account.id === 1);
    let slug = account.slug;
    let message = false;
    this._emailService.clientSignatureLink(submission, submission, slug, message);
  }

}
