<div class="container container-dialog theme">
  <div class="row">
    <div class="col">
      <h2 class="pb-sm" mat-dialog-title>Unterschrift zeichnen</h2>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="canvas-wrapper">
        <canvas #canvas width="{{testwidth}}" height="{{testheight}}"></canvas>
      </div>
    </div>
  </div>
  <div class="row pt-xsm">
    <div class="col text-center">
      <span *ngIf="!data.img">Hier unterzeichnen</span>
      <a *ngIf="data.img" (click)="onClearClick()">Unterschrift löschen</a>
    </div>
  </div>
  <div class="row pt-sm pb-sm">
    <div class="col">
      <button mat-button mat-stroked-button color="primary" (click)="onNoClick()">Abbrechen</button>
    </div>
    <div class="col text-right">
      <button [disabled]="!data.img"
              mat-button
              mat-flat-button
              color="primary"
              [mat-dialog-close]="data.img"
              cdkFocusInitial>
        hinzufügen
      </button>
    </div>
  </div>
</div>
