import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { ApiService } from "../shared/services/api.service";
import { PersonalInformationComponent } from "./personal-information/personal-information.component";
import { MandatFormService } from "../shared/services/mandat-form.service";
import { AccountManagerComponent } from "./account-manager/account-manager.component";
import { InsuranceContractsComponent } from "./insurance-contracts/insurance-contracts.component";
import { SignatureComponent } from "./signature/signature.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PdfService } from "../shared/services/pdf.service";
import { ThemeService } from "../shared/services/theme.service";
import { ActivatedRoute, Route } from "@angular/router";
import { EmailService } from "../shared/services/email.service";
import { LoadingService } from "../shared/services/loading.service";
import { AuthorizationService } from "../shared/services/authorization.service";


@Component({
  selector: 'app-broker-mandate-form',
  templateUrl: './broker-mandate-form.component.html',
  styleUrls: ['./broker-mandate-form.component.scss']
})
export class BrokerMandateFormComponent implements OnInit {

  @ViewChild(PersonalInformationComponent) personalInformation: PersonalInformationComponent = null as any;
  @ViewChild(AccountManagerComponent) accountManager: AccountManagerComponent = null as any;
  @ViewChild(InsuranceContractsComponent) insuranceContracts: InsuranceContractsComponent = null as any;
  @ViewChild(SignatureComponent) signature: SignatureComponent = null as any;

  @ViewChild("stepper", {static: false})
  stepper: any;
  stepperOrientation: Observable<StepperOrientation>;
  currentStep: any;
  isSigned: boolean = false;
  logoUrl: any;

  preSavedData: any;
  isToSign = false;
  isLoading: boolean = false;
  isAuthorized = false;

  constructor(private _formBuilder: FormBuilder, breakpointObserver: BreakpointObserver,
              private _apiService: ApiService,
              private _pdfService: PdfService,
              private _mandatFormService: MandatFormService,
              private http: HttpClient,
              private _themeService: ThemeService,
              private _route: ActivatedRoute,
              private _emailService: EmailService,
              private _loadingService: LoadingService,
              private _authService: AuthorizationService) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));

  }

  ngOnInit() {
    this.getLogo();
    this.checkIfLoading();
    this.ifAuthorized();

    this._route.paramMap.subscribe(params => {
      let id = params.get('id');
      if (id) {
        this.getMandatDetail(id);
      }
    });
  }

  ifAuthorized() {
    this._authService.isAuthenticated.subscribe((response: any) => {
      this.isAuthorized = response;
    })
  }

  getMandatDetail(id: any) {
    this._apiService.getMandateDetail(id).subscribe({
      next: (data: any) => {
        if (data.pdf_files == 0) {
          this.isToSign = true;
          this._mandatFormService.preSavedData.next(data);
          this.preSavedData = data;
        }
      },
      error: (error: any) => {
        this._mandatFormService.preSavedData.next('');
        console.error('There was an error!', error);
      }
    });
  }

  getLogo() {
    this._themeService.logoObservable.subscribe((response: any) => {
      this.logoUrl = response;
    })
  }

  checkIfLoading() {
    this._loadingService.isLoading.subscribe((response: any) => {
      this.isLoading = response;
    })
  }

  get step1() {
    if (this.isToSign) {
      return null as any;
    }
    if (this.personalInformation && this.personalInformation.addressFormGroup.valid) {
      this._mandatFormService.setPersonalInformation(this.personalInformation.addressFormGroup);
    }
    return this.personalInformation ? this.personalInformation.addressFormGroup : null as any;
  }

  get step2() {
    if (this.isToSign) {
      return null as any;
    }
    if (this.accountManager) {
      if (this.accountManager.slug) {
        if (this.accountManager.consultantCtrl.valid) {
          this._mandatFormService.setRegisteredAccountManager(this.accountManager.consultantCtrl, this.accountManager.company);
        }
        return this.accountManager ? this.accountManager.consultantCtrl : null as any;
      } else {
        if (this.accountManager.accountManagerFormGroup.valid) {
          this._mandatFormService.setUnregisteredAccountManager(this.accountManager.accountManagerFormGroup);
        }
        return this.accountManager ? this.accountManager.accountManagerFormGroup : null as any;
      }
    }
  }

  get step3() {
    return null as any;
  }

  get step4() {
    if (this.stepper && this.stepper.selectedIndex == 3) {
      if (this.insuranceContracts.selectionList.length > 0) {
        this.insuranceContracts.addNewContract();
      }
    }
    if (this.signature) {
      if (this.signature.img) {
        this.isSigned = true;
      }
    }
    return null as any
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  submitMandate() {
    if (this.preSavedData) {
      this.updateMandate();
    } else {
      this.createMandate();
    }
  }

  createMandate() {
    let message = true;
    this._loadingService.isLoading.next(true);
    let formData = this.getFormData();
    console.log(formData);
    this._apiService.saveForLater(formData).subscribe({
      next: (data: any) => {
        if (data.pdf_files.length > 0) {
          this._emailService.clientEmailConfirmation(formData);
          this._emailService.managerEmailConfirmation(formData);
        } else {
          this._emailService.clientSignatureLink(formData, data, this._route.snapshot.paramMap.get('slug'), message);
        }
      },
      error: (error: any) => {
        console.error('There was an error!', error);
      }
    });
  }

  updateMandate() {
    this._loadingService.isLoading.next(true);
    let formData = this.getFormData()
    this._apiService.updateMandateDetail(this.preSavedData.id, formData).subscribe({
      next: (data: any) => {
        console.log(data);
        this._emailService.clientEmailConfirmation(data);
        this._emailService.managerEmailConfirmation(data);
      },
      error: (error: any) => {
        console.error('There was an error!', error);
      }
    });
  }

  getFormData() {
    let formData: any;
    let client = this._mandatFormService.personalInformation;
    let manager = this._mandatFormService.accountManager;
    let policeFormData = this.getPoliceFormData();
    let pdfFormData = this.getPdfFormData();

    if (!this.preSavedData) {
      formData = {
        "mandate_client": client,
        "mandate_manager": manager,
        "broker_mandat": 1,
        "account": this._mandatFormService.accountId,
        "pdf_files": pdfFormData,
        "police": policeFormData
      }
    }


    if (this.preSavedData && this.preSavedData.police.length === 0 && this._mandatFormService.contractList) {
      formData = {
        "pdf_files": pdfFormData,
        "police": policeFormData
      }
    }

    if (this.preSavedData && this.preSavedData.police.length > 0) {
      formData = {
        "pdf_files": pdfFormData,
      }
    }

    return formData;
  }

  getPdfFormData() {
    let bscPdf = {
      "name": 'BSC Maklermandat',
      "pdf_file": this._pdfService.binaryPdf,
    }

    let mobiPdf = {
      "name": 'Die Mobiliar',
      "pdf_file": this._pdfService.mobiBinaryPdf,
    }

    let agbPdf = {
      "name": 'Allgemeine Geschäftsbedingungen',
      "pdf_file": this._pdfService.agbBinaryPdf,
    }

    if (this._pdfService.mobiBinaryPdf && this._pdfService.binaryPdf && this._pdfService.agbBinaryPdf) {
      return [bscPdf, mobiPdf, agbPdf];
    } else if (this._pdfService.binaryPdf && this._pdfService.agbBinaryPdf) {
      return [bscPdf, agbPdf];
    }

    return [];
  }

  getPoliceFormData() {
    if (this._mandatFormService.contractList) {
      const array = Object.entries(
        this._mandatFormService.contractList).map(([key, value]) => ({key: key, value: value})
      );
      return array.map(item => item.value).flat();
    }
    return [];
  }

  // aktuell nicht verwendet
  //call this with this.downloadPdf(data.pdf_file, "test.pdf");
  downloadPdf(base64String: any, fileName: any) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }


}
