import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthorizationService } from "../../services/authorization.service";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { HttpHeaders } from "@angular/common/http";
import { repeat } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { validateVerticalPosition } from "@angular/cdk/overlay";
import { ThemeService } from "../../services/theme.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLoggedIn = false;
  logoUrl: any;
  logoHomeLinkeUrl: any


  constructor(private _authService: AuthorizationService,
              private _apiService: ApiService,
              private _router: Router,
              private _snackBar: MatSnackBar,
              private _themeService: ThemeService) {
  }

  ngOnInit() {
    this._authService.authHeaders();
    this._authService.isAuthenticated.subscribe((response: any) => {
        this.isLoggedIn = response;
    });
    this.getLogo();
    this.getLogoHomeUrl()
  }

  getLogo(){
    this._themeService.logoObservable.subscribe((response:any) => {
      this.logoUrl = response;
    })
  }

  getLogoHomeUrl(){
    this._themeService.urlObservable.subscribe((response:any) => {
      this.logoHomeLinkeUrl = response;
    })
  }

  logout() {
    this._apiService.logout(this._authService.authHeaders()).subscribe({
      next: (response: any) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        this._authService.isAuthenticated.next(false);
        this._router.navigate(['login']);
        this._snackBar.open('erfolgreich abgemeldet', '', {
          duration: 4000,
        });
      },
      error: (error: any) => {
        console.error('There was an error!', error);
      }
    });
  }

}
