<div id="header">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col">
        <a routerLink="{{ logoHomeLinkeUrl }}">
          <img src="{{ logoUrl }}" class="logo">
        </a>
      </div>
      <div class="col" *ngIf="isLoggedIn">
        <button mat-icon-button
                [matMenuTriggerFor]="menu">
          <mat-icon>account_circle</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <a mat-menu-item [routerLink]="['/account']">Account</a>
          <a mat-menu-item (click)="logout()">Logout</a>
        </mat-menu>
      </div>
      <div class="col" *ngIf="!isLoggedIn">
        <a mat-icon-button [routerLink]="['/login']">
          <mat-icon>login</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
