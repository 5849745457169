<img src="../../../assets/images/images/maklermandat-mobile.jpg" class="full-width mobile">
<div class="container">

  <!--  Einleitung -->
  <div class="row row align-items-center">
    <div class="col col-12 col-lg-6 order-lg-2 border-box pb-sm desktop">
      <img src="../../../assets/images/images/maklermandat-desktop.jpg" class="full-width">
    </div>
    <div class="col col-12 col-lg-6 order-lg-1 border-box">
      <h2 class="overline pb-sm">Life Gate Consulting AG</h2>
      <h1 class="pb-sm">Maklermandat</h1>
      <h2 class="pb-sm">
        Durch das Maklermandat verhelfen wir Ihnen zu einem entspannten Umgang mit Ihren Versicherungsangelegenheiten.
        Sie haben nur noch eine Ansprechperson über alle Versicherungssparten- und Gesellschaften, die Sie bei uns im
        Mandat wünschen.
      </h2>
      <h2 class="pb-sm">
        Wir freuen uns Sie in Zukunft persönlich
        betreuen zu dürfen.
      </h2>
      <a [routerLink]="['/maklermandat']" mat-button mat-flat-button color="primary">Maklermandat starten</a>
    </div>
  </div>

  <!--  Dienstleistungen -->
  <div class="row pt-xl">
    <h2 class="pb-sm">Von unseren Service-Dienstleistungen profitieren:</h2>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <img src="../../../assets/images/icons/versicherungsvertraege.svg">
        </div>
        <div class="card-content pt-sm">
          <h2>Betreuung</h2>
          <p>Sauber und effizient über alle Versicherungsverträge</p>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <img src="../../../assets/images/icons/vergleich.svg">
        </div>
        <div class="card-content pt-sm">
          <h2>Prüfung</h2>
          <p>Periodischer Vergleich des Marktes</p>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <img src="../../../assets/images/icons/kundenberater.svg">
        </div>
        <div class="card-content pt-sm">
          <h2>Persönlich</h2>
          <p>Betreut durch Ihren Kundenberater oder die Hotline</p>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <img src="../../../assets/images/icons/vertragsanpassung.svg">
        </div>
        <div class="card-content pt-sm">
          <h2>Koordination</h2>
          <p>Kommunikation mit den einzelnen Versicherungsgesellschaften</p>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <img src="../../../assets/images/icons/schadensfall.svg">
        </div>
        <div class="card-content pt-sm">
          <h2>Unterstützung</h2>
          <p>z.Bsp. im Schadenfall</p>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <img src="../../../assets/images/icons/versicherungsgesellschaften.svg">
        </div>
        <div class="card-content pt-sm">
          <h2>All in One</h2>
          <p>Eine Ansprechperson über alle Gesellschaften</p>
        </div>
      </div>
    </div>

  </div>

  <div class="row pt-xl pb-xl">
    <div class="col col-12 col-lg-6 pb-sm">
      <h1>Bei weiteren Fragen helfen wir gerne weiter.</h1>
    </div>
    <div class="col col-12 col-lg-6">
      Kontaktieren Sie unseren per Telefon unter <a href="tel:+41 44 552 35 00">+41 44 552 35 00</a> oder per E-Mail unter
      <a href="mailto:mandate@life-gate.ch">mandate@life-gate.ch</a>.
      Wir sind Montag bis Freitag von 8 Uhr bis 18 Uhr für Sie erreichbar.
    </div>
  </div>

  <div class="row pt-sm pb-sm">
    <div class="col col-12 pb-sm">
      <a href="impressum" [routerLink]="['impressum']" style="text-decoration: none; margin-right: 12px;">Impressum</a>
      <a href="datenschutz" [routerLink]="['datenschutz']" style="text-decoration: none; margin-right: 12px;">Datenschutz</a>
      <a href="disclaimer" [routerLink]="['disclaimer']" style="text-decoration: none; margin-right: 12px;">Disclaimer</a>
      <a href="agb" [routerLink]="['agb']" style="text-decoration: none;">AGB's</a>
    </div>
  </div>
</div>
