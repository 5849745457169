import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MandatFormService } from "../../shared/services/mandat-form.service";

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit{
  successType: any;

  constructor(private _route: ActivatedRoute,
              private _mandatFormService: MandatFormService) { }

  ngOnInit() {
    this._mandatFormService.clearData();
    this.successType = this._route.snapshot.paramMap.get('type');
  }

}
