<ng-container *ngIf="!preSavedData">
  <div class="row pt-xl" *ngIf="!slug">
    <div class="col">
      <h1 class="pb-sm">Kundenberater</h1>
      <h2 class="col-lg-9 pb-sm">
        Wenn Sie keinen Kundenberater angeben, ist die Life Gate Consulting AG als Broker für die Verwaltung des
        Versicherungsportfeuilles zuständig.
      </h2>
    </div>
  </div>

  <div class="row pt-xl" *ngIf="slug">
    <div class="col">
      <h1 class="pb-sm">Kundenberater</h1>
      <h2 class="col-lg-9 pb-sm">
        wird als Broker für die Verwaltung des Versicherungsportfeuilles zuständig sein.
        Wählen Sie den zuständigen Kundenbetreuer, der Sie persönlich beraten wird.
      </h2>
    </div>
  </div>

  <form *ngIf="slug">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Kundenberater</mat-label>
          <mat-select [(value)]="selected"
                      [formControl]="consultantCtrl"
                      placeholder="Kundenberater finden..."
                      #singleSelect
                      required>
            <mat-option>
              <ngx-mat-select-search [formControl]="consultantFilterCtrl"></ngx-mat-select-search>
            </mat-option>
            <mat-option>Eingabe löschen</mat-option>
            <mat-option *ngFor="let consultant of filteredConsultants | async" [value]="consultant">
              {{ consultant.first_name }} {{ consultant.last_name }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix>expand_more</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-container>


<form *ngIf="!slug" [formGroup]="accountManagerFormGroup">
  <div class="row">
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Firmenname</mat-label>
        <input matInput placeholder="Firmenname" formControlName="companyName">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Strasse Nr.</mat-label>
        <input matInput placeholder="Strasse" formControlName="street">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>PLZ</mat-label>
        <input matInput placeholder="PLZ" formControlName="postCode">
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Ort</mat-label>
        <input matInput placeholder="Ort" formControlName="city">
      </mat-form-field>
    </div>
  </div>

  <div class="row pt-md">
    <div class="col-md">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Vorname</mat-label>
        <input matInput placeholder="Vorname" formControlName="firstName">
      </mat-form-field>
    </div>
    <div class="col-md">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Nachname" formControlName="lastName">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>E-Mail</mat-label>
        <input matInput placeholder="E-Mail" formControlName="email" required>
      </mat-form-field>
    </div>
    <div class="col-md">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Telefon</mat-label>
        <input matInput placeholder="Telefon" formControlName="phone" required>
      </mat-form-field>
    </div>
  </div>
</form>


<ng-container *ngIf="preSavedData">
  <div class="row pt-xl">
    <div class="col">
      <h1 class="pb-sm">Ihr Kundenberater</h1>
      <h2 class="col-lg-9 pb-xsm">
        {{preSavedData.company }}<br>
        {{preSavedData.street }}<br>
        {{preSavedData.post_code }} {{preSavedData.city }}<br><br>
        {{preSavedData.first_name }} {{preSavedData.last_name }}<br>
        {{preSavedData.email }}<br>
        {{preSavedData.phone }}<br>
      </h2>
    </div>
  </div>
</ng-container>
