import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiService } from "../../shared/services/api.service";
import { FormBuilder, Validators } from "@angular/forms";
import { MandatFormService } from "../../shared/services/mandat-form.service";

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent {

  emailRegex: any;
  addressFormGroup: any;
  preSavedData: any;

  constructor(private _apiService: ApiService,
              private _mandatFormService: MandatFormService,
              private _formBuilder: FormBuilder) {

    this.emailRegex = this._mandatFormService.emailRegex;
    this.addressFormGroup = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      street: ['', Validators.required],
      postCode: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      city: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      phone: ['', Validators.required],
    });

    this.checkIfPrefilled();
  }

  checkIfPrefilled() {
    this._mandatFormService.preSavedData.subscribe((response: any) => {
      this.preSavedData = response.mandate_client;
      if(this.preSavedData){
        this._mandatFormService.personalInformation = this.preSavedData;
      }
      //this.addressFormGroup.get('firstName').setValue(this.preSavedData.first_name);
    })
  }

}
